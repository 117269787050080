.DisclaimerModal-Container{
    display: grid;
    border-radius: 5px;
    position: absolute;
    width: 50vw;
    min-width: 350px;
    top: 17.5%;
    background-color: white;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    z-index: 100;
    border: 1px solid #141C25;
    background-color: #141C25;
    color: white;
}

.DisclaimerModal-Page{
    display: grid;
    grid-template-rows: 1fr 50px;
    grid-template-areas: 
        'Content'
        'Buttons';
}

.DisclaimerModal-Button-Single{
    grid-area: Buttons;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    padding: 10px;
}

.DisclaimerModal-Primary-Button{
    border: 2.5px solid white;
    background-color: white;
    color: #141C25;;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 5px 0px 5px 0px;
    font-weight: 600;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
    font-size: var(--body);
  }